import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import BackgroundSlider from '../BackgroundSlider';

export default function Subjects() {

  
  return(
    <>
    {/*   <h1 className='subjects'>SUBJECTS</h1>
 */}

 
      <BackgroundSlider/>
      <Footer/>
      
    </>
  )


  
}
