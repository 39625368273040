



import './App.css'
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/pages/Home';
import Subjects from './components/pages/Subjects';
import TutorOffers from './components/pages/TutorOffers';
import SignIn from './components/pages/SignIn';




function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home/>} exact  />
          <Route path='/subjects' element={<Subjects/>} />
          <Route path='/tutoroffers' element={<TutorOffers/>} />
          <Route path='/sign-in' element={<SignIn/>} />
        </Routes>
       
      </Router>


    </>
  );
}

export default App;