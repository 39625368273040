import React from 'react';
import '../../App.css';
import Footer from '../Footer';


export default function TutorOffers() {
  return(
    <>
    <h1 className='tutoroffers'>TUTOR OFFER</h1>
    
      <Footer />
   
    </>
  )
}
