const imageSlide = [
    {
      
        url: 'images/img-1.jpg',
        titel: 'Categories',
        body: 'All Subjects from A to Z',
       

    },

    {
        url: 'images/img-2.jpg',
        titel: 'Packages',
        body: 'All Packages',
       

    },
    {
        url: 'images/img-3.jpg',
        titel: 'Top Favorites',
        body: 'Favorite Tutors and Courses in the week',
       

    }
]

export default imageSlide