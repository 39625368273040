import React from 'react';
import '../../App.css';
import Footer from '../Footer';

import LoginSignup from '../LoginSignup';

export default function SignIn() {


  return(

      <>
        {/* <h1 className='sign-in'>
          </h1>
        */}
  
      
      <LoginSignup/>
     

     
      <Footer/>
      </>
  )


  
}
