
import './BackgroundSlider.css';
import imageSlide from '../data';
import  {useEffect, useState } from 'react';

const BackgroundSlider = () => {

  const [currentState, SetCurrentState] =  useState(0)
  useEffect(()=>{

    const timer = setTimeout(()=>{

        if(currentState===2){
            SetCurrentState(0)
        }else{
            SetCurrentState(currentState+1) 
        }
       

    }, 10000)
    return ()=> clearTimeout(timer)
  }, [currentState])

  const bgImageStyle = {

   
   backgroundImage:`url(${imageSlide[currentState].url})`
}



  const goToNext =(currentState) =>{

    SetCurrentState(currentState)

  }

  const changeColor = (currentState) =>{

    var circleStyle = null;
    
    if(currentState===1){
      circleStyle = {
        width: 10,
        height: 10,
        backgroundColor: 'red',
  
      } 
    } else  if(currentState===2){
      circleStyle = {
        width: 10,
        height: 10,
        backgroundColor: 'yellow'
      } 
    } else  {
      circleStyle = {
        width: 10,
        height: 10,
        backgroundColor: 'green'
      } 
    } 


    return (
      <div  style={circleStyle}>
      </div>
    );
   
   
  }

  return (
    <div className='BKSlider' style={bgImageStyle} >
    
       
            <div className = 'description'>

                <h1>{imageSlide[currentState].titel}</h1>
                <p> {imageSlide[currentState].body}</p>
                <div className='carousel-boullt'>
                {
                   
                   imageSlide.map((imageSlide,currentState)=>(

                   <span key={currentState} onClick={()=>goToNext(currentState)} > {changeColor(currentState)}</span>

                   ))
                 }

            </div>
            </div>
    </div>
  )
}

export default BackgroundSlider
